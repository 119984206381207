import axios from 'axios';
import {
    FETCH_CALENDAR_EVENTS_FAILURE,
    FETCH_CALENDAR_EVENTS_REQUEST,
    FETCH_CALENDAR_EVENTS_SUCCESS,
} from '../constants/eventsTypes';

const daysOfWeek = Object.freeze([
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
]);

const getDateOfWeekDay = (dayOfWeek) => {
    const today = new Date();
    const currentDay = today.getDay();
    const targetDay = daysOfWeek.indexOf(dayOfWeek) + 1;

    const diff = targetDay - currentDay;
    const resultDate = new Date(today);
    resultDate.setDate(today.getDate() + diff);
    return resultDate;
};

const setTimeForDate = (date, time) => {
    const [hours, minutes] = time.split(':').map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
        throw new Error('Invalid time format');
    }
    const resultDate = new Date(date);
    resultDate.setHours(hours, minutes);
    return resultDate;
};

const fetchCalendarEventsRequest = () => {
    return {
        type: FETCH_CALENDAR_EVENTS_REQUEST,
    };
};

const fetchCalendarEventsSuccess = (payload) => {
    return {
        type: FETCH_CALENDAR_EVENTS_SUCCESS,
        payload,
    };
};

const fetchCalendarEventsFailure = (error) => {
    return {
        type: FETCH_CALENDAR_EVENTS_FAILURE,
        payload: error,
    };
};

export const fetchCalendarEvents = () => {
    return (dispatch) => {
        dispatch(fetchCalendarEventsRequest);
        axios
            .get(`${process.env.REACT_APP_CMS_URL}/events?populate=icon`)
            .then((res) => {
                const fetchedEvents = res.data.data.map((event) => {
                    const resultDate = getDateOfWeekDay(event.dayOfWeek);
                    const startDate = setTimeForDate(
                        resultDate,
                        event.startTime
                    );
                    const endDate = setTimeForDate(resultDate, event.endTime);

                    return {
                        title: event.name,
                        start: startDate,
                        end: endDate,
                        resource: {
                            url: event.url,
                            color: event.color,
                            icon: event.icon?.data,
                        },
                    };
                });

                dispatch(fetchCalendarEventsSuccess(fetchedEvents));
            })
            .catch((err) => {
                dispatch(fetchCalendarEventsFailure(err.message));
            });
    };
};
