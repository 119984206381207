import moment from 'moment';
import React from 'react';

const CalendarEvent = ({ event }) => {
    const imagePath =
        event.resource.icon?.formats?.thumbnail?.url ||
        event.resource.icon?.url;

    return (
        <div className="event-content">
            <div className="event-title">
                <a href={event.resource.url} target="_blank">
                    {event.title}
                </a>
                {event.resource.icon && (
                    <img
                        src={`${process.env.REACT_APP_CMS_URL_IMAGE}${imagePath}`}
                        alt={event.title}
                        className="event-partner-logo"
                    />
                )}
            </div>
            <add-to-calendar-button
                id="calendar-button"
                options="'Apple', 'Google', 'iCal', 'Outlook.com'"
                name={event.title}
                startDate={moment(event.start).format('YYYY-MM-DD')}
                endDate={moment(event.end).format('YYYY-MM-DD')}
                startTime={moment(event.start).format('HH:mm')}
                endTime={moment(event.end).format('HH:mm')}
                language="de"
                size="3"
                timeZone="currentBrowser"
                hideBackground
                styleLight="--btn-background: transparent; --btn-text: #fff; --btn-background-hover: rgba(255, 255, 255, 0.1); --btn-text-hover: #fff;"
                trigger="click"
                hideTextLabelButton
            />
        </div>
    );
};

export default CalendarEvent;
