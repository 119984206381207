import React, { useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { fetchGeoData } from '../../store/actions';
import FontAwesome from '../uiStyle/FontAwesome';
import './style.scss';

/**
 * SidebarMenu Component
 * - Displays a collapsible sidebar menu with nested navigation.
 * - Supports three levels of navigation (main menu, submenu, and third menu).
 */
const SidebarMenu = ({ menus, sideShow, setSideShow, className }) => {
    // State for toggling submenus and third-level menus
    const [sMenu, setSMenu] = useState(null); // Tracks open submenu
    const [stMenu, setSTMenu] = useState(null); // Tracks open third menu

    return (
        <div
            className={`sidebarMenu ${sideShow ? '' : 'hideSideMenu'} ${
                className || ''
            }`}
        >
            {/* Close button */}
            <span className="clox" onClick={() => setSideShow(false)}>
                Close
            </span>
            {/* Main Navigation Menu */}
            <ul className="navBar">
                {menus.length > 0
                    ? menus.map((item) => (
                          <li
                              key={item.id}
                              className={`${item.child ? 'has_sub' : ''}`}
                          >
                              {item.child ? (
                                  // Toggle submenu visibility
                                  <div
                                      onClick={() =>
                                          setSMenu(
                                              item.id === sMenu ? null : item.id
                                          )
                                      }
                                      className={
                                          sMenu === item.id ? 'active' : ''
                                      }
                                  >
                                      {item.linkText}
                                      <FontAwesome
                                          name={
                                              sMenu === item.id
                                                  ? 'angle-down active'
                                                  : 'angle-down'
                                          }
                                      />
                                  </div>
                              ) : (
                                  // Link for items without children
                                  <NavLink
                                      exact
                                      className={
                                          sMenu === item.id ? 'active' : ''
                                      }
                                      to={item.link}
                                  >
                                      {item.linkText}{' '}
                                  </NavLink>
                              )}
                              {/* Submenu */}
                              {item.child ? (
                                  <Collapse isOpen={sMenu === item.id}>
                                      <ul className="subMenu">
                                          {item.submenu.map((subItem) => (
                                              <li
                                                  key={subItem.id}
                                                  className={`${
                                                      subItem.child
                                                          ? 'has_sub'
                                                          : ''
                                                  }`}
                                              >
                                                  {subItem.child ? (
                                                      // Toggle third-level menu visibility
                                                      <div
                                                          onClick={() =>
                                                              setSTMenu(
                                                                  subItem.id ===
                                                                      stMenu
                                                                      ? null
                                                                      : subItem.id
                                                              )
                                                          }
                                                          className={
                                                              stMenu ===
                                                              subItem.id
                                                                  ? 'active'
                                                                  : ''
                                                          }
                                                      >
                                                          {subItem.linkText}
                                                          <FontAwesome
                                                              name={
                                                                  stMenu ===
                                                                  subItem.id
                                                                      ? 'angle-down active'
                                                                      : 'angle-down'
                                                              }
                                                          />
                                                      </div>
                                                  ) : (
                                                      // Link for submenu items without children
                                                      <NavLink
                                                          exact
                                                          to={subItem.link}
                                                      >
                                                          {subItem.linkText}
                                                      </NavLink>
                                                  )}
                                                  {/* Third-Level Menu */}
                                                  {subItem.third_menu ? (
                                                      <Collapse
                                                          isOpen={
                                                              stMenu ===
                                                              subItem.id
                                                          }
                                                      >
                                                          <ul className="thirdMenu">
                                                              {subItem.third_menu.map(
                                                                  (
                                                                      thirdItem
                                                                  ) => (
                                                                      <li
                                                                          key={
                                                                              thirdItem.id
                                                                          }
                                                                      >
                                                                          <NavLink
                                                                              to={
                                                                                  thirdItem.link
                                                                              }
                                                                          >
                                                                              {
                                                                                  thirdItem.linkText
                                                                              }
                                                                          </NavLink>
                                                                      </li>
                                                                  )
                                                              )}
                                                          </ul>
                                                      </Collapse>
                                                  ) : null}
                                              </li>
                                          ))}
                                      </ul>
                                  </Collapse>
                              ) : null}
                          </li>
                      ))
                    : null}
            </ul>
        </div>
    );
};

/**
 * Maps dispatch functions to component props.
 */
const mapDispatchToProps = (dispatch) => {
    return {
        fetchGeoData: (filters) => dispatch(fetchGeoData(filters)),
    };
};

// Connect component to Redux store
export default connect(null, mapDispatchToProps)(SidebarMenu);
