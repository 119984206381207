import { combineReducers } from 'redux';
import adsReducer from './adsReducer';
import articleReducer from './articleReducer';
import articlesReducer from './articlesReducer';
import calendarReducer from './calendarReducer';
import categoryReducer from './categoryReducer';
import eventsReducer from './eventsReducer';
import geoDataReducer from './geoDataReducer';
import articlesGtTipsReducer from './gtTipsReducer';
import articlesNewsReducer from './newsReducer';
import offerReducer from './offerReducer';
import offersReducer from './offersReducer';
import pageReducer from './pageReducer';
import articlesPflegeReducer from './pflegeReducer';
import articlesRetreatReducer from './retreatReducer';
import searchReducer from './searchReducer';
import squirrelNewsReducer from './squirrelNewsReducer';
import articlesWissenReducer from './wissenReducer';

const rootReducer = combineReducers({
    articles: articlesReducer,
    article: articleReducer,
    category: categoryReducer,
    ads: adsReducer,
    page: pageReducer,
    offers: offersReducer,
    offer: offerReducer,
    geoData: geoDataReducer,
    searchData: searchReducer,
    articlesWissen: articlesWissenReducer,
    articlesGtTips: articlesGtTipsReducer,
    articlesNews: articlesNewsReducer,
    articlesPflege: articlesPflegeReducer,
    calendar: calendarReducer,
    articlesRetreat: articlesRetreatReducer,
    squirrelNewsArticles: squirrelNewsReducer,
    events: eventsReducer,
});
export default rootReducer;
