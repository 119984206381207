import { FETCH_CALENDAR_EVENTS_SUCCESS } from '../constants/eventsTypes';

const initialState = {
    loading: false,
    events: [],
    error: '',
};

const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CALENDAR_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                events: action.payload,
                error: '',
            };
        default:
            return state;
    }
};

export default eventsReducer;
