import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect } from 'react-redux';
import { fetchCalendarEvents } from '../../store/actions/eventsActions';
import CalendarEvent from '../CalendarEvent';
import './index.scss';

const localizer = momentLocalizer(moment);

const EventsCalendar = ({ fetchCalendarEvents, events }) => {
    const earliestEvent = useMemo(
        () =>
            events.reduce((earliest, current) => {
                const currentStartTime = moment(current.start).format('HH:mm');
                const earliestStartTime = moment(earliest.start).format(
                    'HH:mm'
                );
                return currentStartTime < earliestStartTime
                    ? current
                    : earliest;
            }, events[0]),
        [events]
    );

    useEffect(() => {
        fetchCalendarEvents();
    }, [fetchCalendarEvents]);

    return events.length && earliestEvent ? (
        <div className="calendar-container">
            <Calendar
                localizer={localizer}
                views={[Views.WEEK]}
                defaultView={Views.WEEK}
                events={events}
                style={{ height: 600 }}
                toolbar={false}
                timeslots={1}
                step={60}
                culture="de-DE"
                selectable={false}
                formats={{
                    dayFormat: (date, _, localizer) =>
                        localizer.format(date, 'dddd'),
                }}
                scrollToTime={new Date(earliestEvent.start)}
                components={{
                    week: {
                        event: CalendarEvent,
                    },
                }}
                eventPropGetter={(event) => ({
                    style: {
                        backgroundColor: event.resource.color,
                    },
                })}
            />
        </div>
    ) : null;
};

const mapStateToProps = (state) => {
    return {
        events: state.events.events,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCalendarEvents: () => dispatch(fetchCalendarEvents()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsCalendar);
